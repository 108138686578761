import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Searchbar from "../components/searchbar";
import coverplaceholder from "../images/cover-container.png";

export const query = graphql`
	query RecipeQuery($id: String!) {
		strapiRecipe(strapiId: { eq: $id }) {
			strapiId
			Name
			Direction
			Description
			Checkpoint
			Preparation
			Coverphoto {
				absolutePath
				publicURL
			}
			IngredientServing {
				ingredient {
					Name
					Unit
				}
				Amount
			}
			Serving
			categories {
				Name
				Slug
				id
			}
			styles {
				Name
				Slug
				id
			}
			updatedAt
		}
	}
`;

const Recipe = ({ data: { strapiRecipe: recipe } }) => {
	return (
		<Layout
			title={recipe.Name}
			description={recipe.Description}
			imagePath={recipe.Coverphoto.publicURL}
		>
			<section className="pb-5 lg:py-5 lg:bg-gray-100">
				<div className="flex flex-col-reverse lg:flex-row max-w-screen-xl mx-auto px-3 py-5 lg:px-10">
					<div className="lg:w-3/4">
						<h1 className="mt-5 lg:mt-0 lg:pb-5 text-3xl lg:text-3xl font-bold">
							{recipe.Name}
						</h1>
					</div>
					<div className="item-right lg:w-1/4">
						<Searchbar />
					</div>
				</div>
				<div className="flex flex-col lg:flex-row max-w-screen-xl mx-auto lg:px-10 text-center lg:text-left">
					<div className="lg:w-3/5">
						<div
							className="bg-cover bg-center bg-no-repeat"
							style={{
								backgroundImage: `url(${recipe.Coverphoto.publicURL})`,
							}}
						>
							<img src={coverplaceholder} alt={recipe.Name}></img>
						</div>
					</div>
					<div className="text-center lg:text-left px-3 lg:px-5 lg:w-2/5">
						<div className="flex py-2">
							<div className="hidden lg:block">食材 </div>
							<div className="mx-auto lg:m-0">
								{recipe.categories.map((cat) => (
									<Link
										to={`/category/${cat.Slug}`}
										className="no-underline"
									>
										<span class="border border-yellow-cust1 rounded-md px-3 py-1 mx-1 text-gray-700">
											#{cat.Name}
										</span>
									</Link>
								))}
							</div>
						</div>
						<div className="flex py-2">
							<div className="hidden lg:block">風格 </div>
							<div className="mx-auto lg:m-0">
								{recipe.styles.map((style) => (
									<Link
										to={`/style/${style.Slug}`}
										className="no-underline"
									>
										<span class="bg-yellow-cust2 rounded-md px-3 py-1 mx-1 text-gray-700">
											#{style.Name}
										</span>
									</Link>
								))}
							</div>
						</div>
						<div className="py-2 markdown">
							<ReactMarkdown source={recipe.Description} />
						</div>
					</div>
				</div>
			</section>

			<section
				id="instruction"
				className="max-w-screen-xl mx-auto pt-10 tracking-wider"
			>
				<div className="flex flex-row px-3 lg:px-10">
					<div className="flex flex-col lg:flex-row">
						<div id="ingredient" className="pb-5 lg:pr-5 lg:w-1/2">
							<h3 className="border-b-2 border-yellow-cust1 text-2xl lg:text-3xl mb-3">
								材料 ({recipe.Serving}人份)
							</h3>
							{recipe.IngredientServing.map((element) => (
								<div className="flex border-dotted lg:border-solid border-b border-gary-300 mb-2 lg:mb-4 text-lg lg:text-xl">
									<div className="w-1/2">
										{element.ingredient.Name}
									</div>
									{renderIngredientAmount(element)}
								</div>
							))}
						</div>
						<div
							id="direction"
							className="pb-5 lg:px-5 lg:w-1/2 flex flex-col"
						>
							<div className="pb-5">
								<h3 className="border-b-2 border-yellow-cust1 text-2xl lg:text-3xl mb-3">
									材料處理
								</h3>
								<div className="mb-2 text-lg text-gray-700 leading-normal lg:leading-8 markdown">
									<ReactMarkdown
										source={recipe.Preparation}
									/>
								</div>
							</div>
							<div className="pb-5">
								<h3 className="border-b-2 border-yellow-cust1 text-2xl lg:text-3xl mb-3">
									做法
								</h3>
								<div className="mb-2 text-lg text-gray-700 leading-normal lg:leading-8 markdown">
									<ReactMarkdown source={recipe.Direction} />
								</div>
							</div>
							{recipe.Checkpoint && (
								<div className="pb-5">
									<h3 className="border-b-2 border-yellow-cust1 text-2xl lg:text-3xl mb-3">
										注意要點
									</h3>
									<div className="mb-2 text-lg text-gray-700 leading-normal lg:leading-8 markdown">
										<ReactMarkdown
											source={recipe.Checkpoint}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
					<div id="right-ad" className="hidden lg:w-1/5"></div>
				</div>
			</section>
			{/* <section id="related" className="pt-10">
				<div className="flex flex-row px-3 lg:px-10">
					<div id="related-dish" className="pb-5 lg:pr-5">
						<h3 className="border-b-2 border-yellow-cust1 text-2xl lg:text-3xl">
							相同食材
						</h3>
					</div>
				</div>
			</section> */}
		</Layout>
	);
};

const renderIngredientAmount = (element) => {
	if (element.Amount > 0) {
		if (element.ingredient.Unit === "ml") {
			if (element.Amount >= 1000) {
				return [
					<div className="w-1/2 text-right">
						{Number((element.Amount / 1000).toFixed(1))} 公升
					</div>,
				];
			} else if (element.Amount >= 15) {
				return [
					<div className="w-1/2 text-right">
						{element.Amount} {element.ingredient.Unit} 或{" "}
						{Number((element.Amount / 15).toFixed(1))} 湯匙
					</div>,
				];
			} else if (element.Amount >= 5) {
				return [
					<div className="w-1/2 text-right">
						{element.Amount} {element.ingredient.Unit} 或{" "}
						{element.Amount / 5} 茶匙
					</div>,
				];
			}
		}

		return [
			<div className="w-1/2 text-right">
				{element.Amount} {element.ingredient.Unit}
			</div>,
		];
	} else return [<div className="w-1/2 text-right">少許</div>];
};

export default Recipe;
